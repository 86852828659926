<template>
  <div>
    <base-icon
      v-if="gentleCycle"
      :title="gentleCycle"
      data-svg="30_grad_waesche.svg"
      large
      svg="M19.7,5.9l-0.4,2c-1,0-1.5,0.4-1.9,0.7c-0.5,0.3-0.8,0.6-1.7,0.6c-0.8,0-1.2-0.3-1.7-0.6c-0.5-0.3-1-0.7-2-0.7
	c-1,0-1.6,0.4-2,0.7C9.5,8.9,9.1,9.1,8.3,9.1S7.1,8.9,6.6,8.6c-0.5-0.3-1-0.7-1.9-0.7l-0.4-2L3.7,6l2.4,12.1h11.8L20.3,6L19.7,5.9z
	 M17.4,17.5H6.6L4.8,8.5c0.7,0,1,0.3,1.4,0.6c0.5,0.3,1,0.7,2,0.7s1.6-0.4,2-0.7c0.5-0.3,0.8-0.6,1.7-0.6c0.8,0,1.2,0.3,1.7,0.6
	c0.5,0.3,1,0.7,2,0.7s1.6-0.4,2-0.7c0.4-0.3,0.8-0.5,1.4-0.6L17.4,17.5z M13.2,12.9c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2
	c0-0.6,0.5-1.2,1.2-1.2C12.6,11.8,13.2,12.3,13.2,12.9z"
    ></base-icon>
    <base-icon
      v-if="handWash"
      :title="handWash"
      data-svg="Handwaesche.svg"
      large
      svg="M12.55 13.58c.26.14.58.04.85-.48l2.8-5.27c.38-.72 1.27-2.41-.15-3.17l-2.65-1.4c-.65-.34-.88-.44-1.57-.09-.19.1-.34.2-.88.48l-3.6
      1.92A.77.77 0 0 0 7 6.65c.27.39.74.26 1.09.09l1.23-.63c.16-.07.27-.08.29-.01.02.07-.12.38-.2.52l-2.52 4.8a.7.7 0 0 0 1.22.64L9.79 8.9a.23.23
      0 0 1 .3-.1.23.23 0 0 1 .1.3l-1.96 3.71a.7.7 0 1 0 1.22.65l1.96-3.71a.24.24 0 0 1 .43.22l-1.55 2.91a.64.64 0 0 0 1.13.6l1.54-2.9a.27.27 0 0
      1 .45.24l-1.08 2.05a.53.53 0 0 0 .22.71zM4.3 5.9l-.6.1 2.4 12.1h11.8L20.3 6l-.6-.1-.4 2c-1 0-1.5.4-1.9.7-.27.16-.48.32-.75.43l-.38.7a3.22 3.22
      0 0 0 1.33-.63c.4-.3.8-.5 1.4-.6l-1.6 9H6.6l-1.8-9c.7 0 1 .3 1.4.6.2.12.4.25.63.37l.34-.57c-.2-.08-.38-.18-.57-.3-.5-.3-1-.7-1.9-.7z"
    ></base-icon>
    <base-icon
      v-if="withoutBleach"
      :title="withoutBleach"
      data-svg="keine_bleichmittel.svg"
      large
      svg="M18.1,16.1l1.9,1.3l-0.6,0.9l0.2,0.3H4.5l0.2-0.3h0L4,17.4L5.9,16l2.9-5.1L4,7.5l0.6-0.9l4.8,3.3L12,5.4
	l2.6,4.5l4.8-3.3L20,7.5l-4.8,3.4L18.1,16.1z M6.4,17.4h11.3l-0.4-0.6L12,13.1l-5.2,3.7L6.4,17.4z M8.1,14.5l3-2.1l-1.2-0.9
	L8.1,14.5z M12,7.6l-1.7,3l1.7,1.2l1.7-1.2L12,7.6z M14.3,11.5L13,12.4l3,2.1L14.3,11.5z"
    ></base-icon>
    <base-icon
      v-if="notChemicallyClean"
      :title="notChemicallyClean"
      data-svg="nicht_chemnisch_reinigen.svg"
      large
      svg="M18.9,12c0,1.3-0.3,2.5-1,3.5l2,1.4l-0.6,0.9l-2.1-1.4c-1.2,1.5-3.2,2.5-5.3,2.5c-2.1,0-4-1-5.3-2.5l-2.1,1.5
	L4,16.9l2.1-1.5c-0.6-1-0.9-2.2-0.9-3.5c0-1.3,0.3-2.5,0.9-3.5L4,7.1l0.6-0.9l2.1,1.5C8,6.1,9.9,5.1,12,5.1c2.1,0,4,1,5.3,2.5
	l2.1-1.4L20,7.1l-2,1.4C18.6,9.5,18.9,10.7,18.9,12z M6.3,12c0,1,0.3,2,0.7,2.8l4-2.8L7,9.2C6.5,10,6.3,11,6.3,12z M12,6.2
	c-1.8,0-3.3,0.8-4.4,2l4.4,3.1l4.4-3.1C15.3,7,13.8,6.2,12,6.2z M12,17.8c1.7,0,3.3-0.8,4.4-2L12,12.7l-4.4,3.1
	C8.7,17,10.3,17.8,12,17.8z M17.8,12c0-1-0.3-2-0.8-2.9L13,12l4.1,2.9C17.5,14,17.8,13,17.8,12z"
    ></base-icon>
    <base-icon
      v-if="notTumbleDry"
      :title="notTumbleDry"
      data-svg="nicht_in_trockner.svg"
      large
      svg="M18.4,7.8l1.8-1.3l-0.4-0.6l-1.4,1V5.6H5.6v1.3l-1.4-1L3.8,6.5l1.8,1.3v8.4l-1.8,1.3l0.4,0.6l1.4-1v1.3h12.8
	v-1.3l1.4,1l0.4-0.6l-1.8-1.3V7.8z M11.4,12l-4.1,3c-0.6-0.9-0.9-1.9-0.9-3c0-1.1,0.3-2.1,0.9-3L11.4,12z M7.7,8.5
	c1-1.2,2.6-2,4.3-2c1.7,0,3.3,0.8,4.3,2L12,11.6L7.7,8.5z M12,12.4l4.3,3.1c-1,1.2-2.6,2-4.3,2c-1.7,0-3.3-0.8-4.3-2L12,12.4z
	 M12.6,12l4.1-3c0.6,0.9,0.9,1.9,0.9,3c0,1.1-0.3,2.1-0.9,3L12.6,12z M17.8,14.2v1.5l-0.6-0.4C17.4,15,17.6,14.6,17.8,14.2z
	 M17.8,9.8c-0.2-0.4-0.3-0.8-0.6-1.1l0.6-0.4V9.8z M17.8,7.4l-1,0.7c-0.7-0.8-1.6-1.5-2.6-1.9h3.5V7.4z M6.2,6.2h3.5
	c-1,0.4-1.9,1-2.6,1.9l-1-0.7V6.2z M6.8,8.7C6.6,9,6.4,9.4,6.2,9.8V8.2L6.8,8.7z M6.2,14.2c0.2,0.4,0.3,0.8,0.6,1.1l-0.6,0.4V14.2z
	 M6.2,16.6l1-0.7c0.7,0.8,1.6,1.5,2.6,1.9H6.2V16.6z M17.8,17.8h-3.5c1-0.4,1.9-1,2.6-1.9l1,0.7V17.8z"
    ></base-icon>
    <base-icon
      v-if="carefulIroningSteam"
      :title="carefulIroningSteam"
      data-svg="ohne_dampf_buegeln.svg"
      large
      svg="M20.2,16.4V4H5.7v0.7h13.9v3.5c-9.8,0-15.7,0.6-15.7,8v0.3h4.6c0,0.4-0.1,0.6-0.3,0.8l-3.4-0.5l-0.1,0.7
	l2.9,0.4c-0.3,0.2-0.5,0.4-0.7,0.8L4.6,19l0.1,0.7l1.8-0.3c-0.1,0.3-0.1,0.6-0.1,0.9H7c0-0.4,0.1-0.8,0.1-1l4.8-0.7l4.8,0.7
	c0.1,0.3,0.1,0.6,0.1,1h0.7c0-0.4,0-0.7-0.1-0.9l1.8,0.3l0.1-0.7l-2.1-0.3c-0.2-0.4-0.5-0.6-0.7-0.8l2.9-0.4l-0.1-0.7l-3.4,0.5
	c-0.1-0.2-0.3-0.4-0.3-0.8H20.2z M19.5,8.8v7h-15C4.7,9.5,9.5,8.8,19.5,8.8z M7.7,18.5c0.1-0.1,0.2-0.2,0.3-0.2
	c0.1-0.1,0.3-0.2,0.4-0.3l1.4,0.2L7.7,18.5z M16,18.3c0.1,0.1,0.2,0.2,0.3,0.2l-2.1-0.3l1.4-0.2C15.8,18.1,15.9,18.2,16,18.3z
	 M15.2,17.4L12,17.9l-3.2-0.5C9,17.1,9,16.8,9.1,16.4h5.8C15,16.8,15,17.1,15.2,17.4z"
    ></base-icon>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'ProductDetailPropertyWashingSymbols',
  components: { BaseIcon },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    gentleCycle() {
      return this.getOption([
        'Im Schonwaschgang bei 30 °C waschen',
        'Utiliser le programme délicat à 30°C',
      ])
    },
    handWash() {
      return this.getOption([
        'Handwäsche bei 30 °C',
        'Lavage à la main à 30 °C',
      ])
    },
    withoutBleach() {
      return this.getOption([
        'Milde Waschmittel ohne Bleichmittel verwenden',
        'Utiliser des lessives douces, sans agent blanchissant',
      ])
    },
    notChemicallyClean() {
      return this.getOption([
        'Nicht chemisch reinigen',
        'Ne pas nettoyer à sec',
      ])
    },
    notTumbleDry() {
      return this.getOption([
        'Nicht im Wäschetrockner trocknen',
        'Ne pas mettre au sèche-linge',
      ])
    },
    carefulIroningSteam() {
      return this.getOption([
        'Vorsicht beim Bügeln mit Dampf!',
        'Prudence avec le repassage vapeur!',
      ])
    },
  },
  methods: {
    getOption(names) {
      return names.find((name) => this.options.includes(name))
    },
  },
}
</script>
